import React from "react";
import Act from "./Act";
const ActsList: React.FC<{ data: Record<string, any> }> = ({ data }) => {
  const { currentActId, acts } = data;
  return (
    <>
      {acts.map((act: any) => (
        <Act
          key={act.id}
          displayName={act.displayName}
          activatedAt={act.activatedAt}
          description={act.description}
          current={act.id === currentActId}
        />
      ))}
    </>
  );
};

export default ActsList;
