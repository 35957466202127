import React, { useContext, createContext, useState } from "react";
import { differenceInMinutes, differenceInHours } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
const jwt = require("jsonwebtoken");

export type AuthInfo = {
  userInfo?: { name?: string; avatar?: string; systemRole?: string };
  token?: string;
  expiresAt?: string;
};

type AuthContextState = {
  authInfo: AuthInfo;
  saveAuthInfo: (info: AuthInfo) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
  isAdmin: () => boolean;
  isTokenExpired: () => boolean;
};

const initialState = {
  authInfo: {
    userInfo: { name: undefined, avatar: undefined, systemRole: undefined },
    token: undefined,
    expiresAt: undefined,
  },
};

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: React.FC = ({ children }) => {
  let token, userInfo, expiresAt;
  if (typeof window !== "undefined") {
    token = localStorage.getItem("token");
    userInfo = localStorage.getItem("userInfo");
    expiresAt = localStorage.getItem("expiresAt");
  }

  const [authInfo, setAuthInfo] = useState<AuthInfo>({
    userInfo: userInfo ? JSON.parse(userInfo) : {},
    token: token ? token : undefined,
    expiresAt: expiresAt ? expiresAt : undefined,
  });

  const saveAuthInfo = ({ token, userInfo, expiresAt }: AuthInfo) => {
    if (typeof window !== "undefined") {
      localStorage.setItem("token", token ? token : "");
      localStorage.setItem(
        "userInfo",
        userInfo ? JSON.stringify(userInfo) : ""
      );
      localStorage.setItem("expiresAt", expiresAt!);
    }
    setAuthInfo({ userInfo, token, expiresAt });
  };

  const logout = () => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("expiresAt");
      setAuthInfo(initialState.authInfo);
    }
  };

  const isAuthenticated = () => {
    if (authInfo.token) {
      try {
        const decodedToken = jwt.verify(
          authInfo.token,
          process.env.REACT_APP_SESSION_SECRET || ""
        );
        return true;
      } catch (err) {
        return false;
      }
    }
    return false;
  };

  const isAdmin = () => {
    const role = authInfo.userInfo?.systemRole;
    return role === "ADMIN" || role === "SUPERADMIN";
  };

  const isTokenExpired = () => {
    if (authInfo.expiresAt) {
      const now = utcToZonedTime(new Date(), "etc/GMT");
      const expiration = utcToZonedTime(
        new Date(parseInt(authInfo.expiresAt, 10) * 1000),
        "etc/GMT"
      );
      const hoursDiff = Math.floor(differenceInHours(expiration, now));
      const minutesDiff = differenceInMinutes(expiration, now) % 60;
      console.log(`token expires in ${hoursDiff}:${minutesDiff}`);
      if (hoursDiff < 1 && minutesDiff < 30) {
        logout();
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authInfo,
        saveAuthInfo,
        logout,
        isAuthenticated,
        isAdmin,
        isTokenExpired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
