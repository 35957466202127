import React from "react";
import ReactJson from "react-json-view";
import { useColorModeValue, VStack } from "@chakra-ui/react";

const blargTheme = {
  base00: "#1a202c",
  base01: "#303030",
  base02: "#202020",
  base03: "#505050",
  base04: "#b0b0b0",
  base05: "#d0d0d0",
  base06: "#e0e0e0",
  base07: "#ffffff",
  base08: "#ff0086",
  base09: "#fd8900",
  base0A: "#aba800",
  base0B: "#00c918",
  base0C: "#1faaaa",
  base0D: "#3777e6",
  base0E: "#c51930",
  base0F: "#cc6633",
};

const HouseData: React.FC<{ data: Record<string, any> }> = ({ data }) => {
  const theme = useColorModeValue("summerfruit:inverted", blargTheme);
  return (
    <VStack w="100%" pt={6} alignItems="flex-start" textAlign="left">
      <ReactJson
        src={data}
        name="house data"
        iconStyle="circle"
        theme={theme}
        style={{ fontSize: ".9rem" }}
        shouldCollapse={(field) => {
          return field.name !== "house data";
        }}
      />
      ;
    </VStack>
  );
};

export default HouseData;
