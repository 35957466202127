import { GraphQLClient, gql } from "graphql-request";
import axios, { AxiosResponse, AxiosError } from "axios";

let client: GraphQLClient;
const getClient = async (): Promise<GraphQLClient> => {
  const graphqlEndpoint = process.env.REACT_APP_LOGIN_ENDPOINT;
  console.log("graphql endpoint: ", graphqlEndpoint);
  if (!client && graphqlEndpoint) {
    client = new GraphQLClient(graphqlEndpoint);
  }
  return client;
};

const AdminLogin = gql`
  mutation AdminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      token
    }
  }
`;

export const adminLogin = async (
  email: string,
  password: string
): Promise<string> => {
  const client = await getClient();
  const response = await client.request(AdminLogin, { email, password });
  const token = response.adminLogin?.token;
  return token;
};

export const getServerTime = async (): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  try {
    response = await axios.get(process.env.REACT_APP_TIME_SERVICE_ENDPOINT!, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error getting server time: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> getServerTime", response?.data);
    return err as AxiosError;
  }
};

export const getHouseData = async (): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  const endpoint = `${process.env.REACT_APP_HOUSEDATA_PATH}/${
    process.env.REACT_APP_HOUSEDATA_FILENAME
  }?t=${Math.floor(new Date().getTime() / 1000)}`!;
  try {
    response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.errors) {
      throw new Error(
        `error getting house data: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    const { response } = err as AxiosError;
    console.error("-----> fetchHouseData", response?.data);
    return err as AxiosError;
  }
};

export type UpdateParams = {
  filename: string;
  data: Record<string, any>[];
  token: string;
  dryrun?: boolean;
  resetForm?: (nextState: any) => void;
};

export const updateHouseData = async ({
  filename,
  data,
  token,
}: UpdateParams): Promise<Record<string, any>> => {
  let response: AxiosResponse;
  try {
    response = await axios.post(
      process.env.REACT_APP_HOUSEDATA_UPDATE_ENDPOINT!,
      { filename, data, dryrun: false },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("axios response: ", response);
    if (response.data.errors) {
      throw new Error(
        `error getting server time: ${JSON.stringify(response.data.errors)}`
      );
    }
    return response.data;
  } catch (err) {
    console.error("-----> updateHouseData", err);
    return err as AxiosError;
  }
};
