import { VStack, SimpleGrid, GridItem } from "@chakra-ui/react";
import { SubmitButton } from "formik-chakra-ui";
import React, { Ref } from "react";
import Dialog from "./Dialog";
import { useAuth } from "./AuthContext";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { updateHouseData, UpdateParams } from "../api";
import { useQueryClient, useMutation } from "react-query";

const ResetForm: React.FC<{ currentActId: string; actCount: number }> = ({
  currentActId,
  actCount,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const cancelRef: Ref<any> = React.useRef();

  const { authInfo } = useAuth();

  const queryClient = useQueryClient();

  const resetMutation = useMutation(
    ({ filename, data, token }: UpdateParams) => {
      return updateHouseData({ filename, data, token });
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData(["houseData"], response.body.updated);
      },
    }
  );

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };
  const handleDialogAction = async () => {
    // set the timestamp to empty string unless it's the current act
    let updates = [];
    let currentAct = parseInt(currentActId, 10);
    for (var i = 0; i < actCount; i++) {
      let update = {
        name: "timestamp",
        path: `acts.${i}.activatedAt`,
        value: i === currentAct ? new Date().toISOString() : "",
      };
      updates.push(update);
    }
    try {
      resetMutation.mutate({
        filename: process.env.REACT_APP_HOUSEDATA_FILENAME!,
        data: updates,
        token: authInfo.token!,
      });
      setDialogIsOpen(false);
    } catch (err: any) {
      console.log(err);
    }
  };
  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={async (values, actions) => {
          try {
            setDialogIsOpen(true);
          } catch (err: any) {
            console.log(err);
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <VStack w="100%" p={0} spacing={10} alignItems="flex-start">
            <Form style={{ width: "100%" }}>
              <SimpleGrid columns={3} w="full">
                <GridItem colSpan={2}>
                  <Field name="submit">
                    {({ field, form }: FieldProps) => (
                      <SubmitButton
                        size="lg"
                        w="full"
                        color={"lightgray"}
                        backgroundColor={"#444444"}
                        _hover={{
                          color: "white",
                          backgroundColor: "darkred",
                        }}
                        type="submit"
                        isLoading={props.isSubmitting}
                      >
                        Reset Timestamps
                      </SubmitButton>
                    )}
                  </Field>
                </GridItem>
              </SimpleGrid>
            </Form>
          </VStack>
        )}
      </Formik>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        cancelRef={cancelRef}
        onClose={handleDialogClose}
        onAction={handleDialogAction}
        title="Reset Timestamps"
        body="Are you sure you want to reset the timestamps?"
        cancelLabel="Cancel"
        actionLabel="Reset"
      />
    </>
  );
};

export default ResetForm;
