import React from "react";
import { useColorModeValue, Heading, Text } from "@chakra-ui/react";
import { format, utcToZonedTime } from "date-fns-tz";

const Act: React.FC<{
  displayName: string;
  activatedAt: string;
  description: string;
  current: boolean;
}> = ({ children, displayName, activatedAt, description, current }) => {
  let formattedGMTTime;
  if (activatedAt && activatedAt.length > 0) {
    const gmtDate = utcToZonedTime(new Date(activatedAt), "etc/GMT");
    formattedGMTTime = format(gmtDate, "E, MMM d — kk:mm:ss 'GMT'");
  }

  const selectedColor = useColorModeValue("black", "white");
  return (
    <>
      <Heading
        size="l"
        textAlign="left"
        textColor={current ? selectedColor : "gray"}
      >
        {displayName}
      </Heading>
      {formattedGMTTime && (
        <Text
          fontSize=".7em"
          textAlign="left"
          textColor={current ? selectedColor : "gray"}
        >
          {formattedGMTTime}
        </Text>
      )}

      <Text
        size="m"
        textAlign="left"
        textColor={current ? selectedColor : "gray"}
        pb={4}
      >
        {description}
      </Text>
    </>
  );
};

export default Act;
