import React from "react";
import { useAuth } from "./AuthContext";
import HouseData from "./HouseData";
import LoginForm from "./LoginForm";
import ActsList from "./ActsList";
import UpdateForm from "./UpdateForm";
import ServerTime from "./ServerTime";
import UserBadge from "./UserBadge";
import ResetForm from "./ResetForm";
import VersionLabel from "./VersionLabel";
import {
  VStack,
  Heading,
  SimpleGrid,
  GridItem,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { useQuery } from "react-query";
import { getHouseData } from "../api";

const Main: React.FC = () => {
  const { authInfo, isAuthenticated, logout } = useAuth();
  const authenticated = isAuthenticated();

  const { isLoading, isError, data, error } = useQuery(
    "houseData",
    getHouseData,
    { refetchInterval: 5000, enabled: isAuthenticated(), staleTime: 4000 }
  );

  if (authenticated && isLoading) {
    return <span>Loading...</span>;
  }

  if (authenticated && isError) {
    return <span>Error: {error}</span>;
  }

  const { name, avatar, systemRole } = authInfo?.userInfo!;

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    console.log("LOGGING OUT!");
    logout();
  };
  return (
    <>
      <ColorModeSwitcher />
      {authenticated ? (
        <VStack w="full" h="full" p={10} spacing={10} alignItems="flex-start">
          <Flex w="full">
            <VStack spacing={1} alignItems="flex-start">
              <Heading size="xl">Blumhouse Mission Control</Heading>
              <ServerTime />
            </VStack>
            <Spacer />
            <UserBadge
              name={name!}
              avatar={avatar!}
              systemRole={systemRole!}
              handleLogout={onLogoutClick}
            />
          </Flex>
          <SimpleGrid columns={2} columnGap={12} rowGap={6} w="full">
            <GridItem colSpan={1}>
              <ActsList data={data!} />
              <ResetForm
                currentActId={data!.currentActId}
                actCount={data!.acts.length}
              />
              <VersionLabel />
            </GridItem>
            <GridItem colSpan={1}>
              <UpdateForm data={data!} />
              <HouseData data={data!} />
            </GridItem>
          </SimpleGrid>
        </VStack>
      ) : (
        <LoginForm />
      )}
    </>
  );
};

export default Main;
