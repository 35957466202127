import {
  FormControl,
  FormLabel,
  VStack,
  SimpleGrid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { SelectControl, SubmitButton } from "formik-chakra-ui";
import React from "react";
import { Formik, FormikProps, Form, Field, FieldProps } from "formik";
import { updateHouseData, UpdateParams } from "../api";
import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "./AuthContext";

const UpdateForm: React.FC<{ data: Record<string, any> }> = ({
  children,
  data,
}) => {
  const { currentActId } = data;
  const { authInfo, isTokenExpired } = useAuth();

  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    ({ filename, data, token }: UpdateParams) => {
      return updateHouseData({ filename, data, token });
    },
    {
      onSuccess: (response, variables) => {
        const { resetForm } = variables;
        queryClient.setQueryData(["houseData"], response.body.updated);
        resetForm!({
          values: { act: response.body.updated.currentActId },
        });
      },
    }
  );

  return (
    <Formik
      initialValues={{ act: currentActId }}
      validateOnChange
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);
        try {
          const expired = isTokenExpired();
          if (!expired) {
            updateMutation.mutate({
              filename: process.env.REACT_APP_HOUSEDATA_FILENAME!,
              data: [
                {
                  name: "currentAct",
                  path: "currentActId",
                  value: values.act,
                },
                {
                  name: "timestamp",
                  path: `acts.${values.act}.activatedAt`,
                  value: new Date().toISOString(),
                },
              ],
              token: authInfo.token!,
              resetForm: actions.resetForm,
            });
          } else {
            console.log("expired!");
          }
        } catch (err: any) {
          console.log(err);
        }
      }}
    >
      {(props: FormikProps<any>) => (
        <VStack w="100%" p={0} spacing={10} alignItems="flex-start">
          <Form style={{ width: "100%" }}>
            <SimpleGrid columns={2} rowGap={6} w="full">
              <GridItem colSpan={2}>
                <Field name="act">
                  {({ field, form }: FieldProps) => (
                    <FormControl>
                      <FormLabel htmlFor="act">Current Act:</FormLabel>
                      <SelectControl name="act" selectProps={{}}>
                        {data.acts.map((act: any) => (
                          <option key={act.id} value={act.id}>
                            {act.displayName}
                          </option>
                        ))}
                      </SelectControl>
                    </FormControl>
                  )}
                </Field>
              </GridItem>
              <GridItem colSpan={1}>
                <Field name="submit">
                  {({ field, form }: FieldProps) => (
                    <>
                      <SubmitButton
                        size="lg"
                        w="full"
                        color={"#cccccc"}
                        backgroundColor={"#990909"}
                        _hover={{
                          color: form.dirty ? "white" : "#cccccc",
                          backgroundColor: form.dirty ? "red" : "#990909",
                        }}
                        isDisabled={!form.dirty || props.isSubmitting}
                        type="submit"
                        isLoading={props.isSubmitting}
                      >
                        UPDATE
                      </SubmitButton>
                    </>
                  )}
                </Field>
              </GridItem>
            </SimpleGrid>
          </Form>
        </VStack>
      )}
    </Formik>
  );
};

export default UpdateForm;
